import React, { useEffect, useState } from 'react';
import useLang from '../hooks/useLang';
import '../styles/layout.css';
import '../styles/NextOrderForm.css';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Alert, Typography, duration } from '@mui/material';
import useApiKey from '../hooks/useApiKey';
import LayoutCard from '../Components/LayoutCard';
import PriceOverlay from '../PriceOverlayV2';
import ActionButton from '../Components/ActionButton';
import { useNavigate } from 'react-router';
import { Desktop, Mobile } from '../lib/Responsive';
import { useSearchParams } from 'react-router-dom';
import ChooseDate from '../Components/ChooseDate';
import VerifyCoupon from '../Components/VerifyCoupon';

const NextOrderCheckout = () => {
	const navigate = useNavigate();
	const { T, t } = useLang('cs');

	const [searchParams, setSearchParams] = useSearchParams();
	const URL_api_key = searchParams.get('api_key');
	const URL_apartment_id = searchParams.get('apartment_id');
	const URL_begin = searchParams.get('begin');
	const URL_duration = searchParams.get('duration');
	const URL_addon_method = searchParams.get('addon_method') == 1;
	const URL_note = searchParams.get('note') ?? '';
	const URL_coupon = searchParams.get('coupon');
	const URL_frequency = searchParams.get('frequency');

	if (URL_api_key) {
		localStorage.setItem('user_data_api_key', JSON.stringify(URL_api_key));
	}

	const isOriginal = !URL_api_key;

	const { apiKey: impliedApiKey } = useApiKey();
	const apiKey = URL_api_key ?? impliedApiKey;

	if (localStorage.getItem('uid') == undefined || localStorage.getItem('token') == undefined) {
		const url = `${process.env.REACT_APP_API_URL}/v1/api-key-to-token?api_key=${apiKey}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				localStorage.setItem('uid', data.uid);
				localStorage.setItem('token', data.token);
			});
	}

	// edits
	const [invoicingEdit, setInvoicingEdit] = useState(false);
	const [formData, setFormData] = useState({});
	const [noteEdit, setNoteEdit] = useState(false);
	const [note, setNote] = useState(URL_note);
	const [couponEdit, setCouponEdit] = useState(false);
	const [coupon, setCoupon] = useState(URL_coupon);
	const [contactEdit, setContactEdit] = useState(false);
	const [contactData, setContactData] = useState({});
	const [dateEdit, setDateEdit] = useState(URL_begin == null ? true : false);
	const [date, setDate] = useState(URL_begin);

	const [apartment, setApartment] = useState(null);
	const [invoiceInfo, setInvoiceInfo] = useState(null);

	if (URL_api_key) {
		// localStorage.setItem('user_data_api_key', URL_api_key);
		// came from sharing
		// no back button
		// no edits, maybe some
		// edit date/time
		// edit hours
		// edit phone, email, note, invoicing
	} else {
		// came from form
		// allow back button -> edits
	}

	useEffect(() => {
		getApartment();
		getInvoiceInfo();
		getContact();
	}, []);

	const getApartment = () => {
		const url = `${process.env.REACT_APP_API_URL}/v1/apartments?api_key=${apiKey}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				setApartment(data.apartments.find((apartment) => apartment.apartment_id == URL_apartment_id));
			});
	};

	const getInvoiceInfo = () => {
		const url = `${process.env.REACT_APP_API_URL}/v1/invoice-info?api_key=${apiKey}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				setInvoiceInfo(data.invoice_info);
				setFormData({
					name: data.invoice_info.invoice_info_name,
					street: data.invoice_info.invoice_info_street,
					postal_code: data.invoice_info.invoice_info_postal_code,
					city: data.invoice_info.invoice_info_city,
					ico: data.invoice_info.invoice_info_ico,
					dic: data.invoice_info.invoice_info_dic,
					email: data.invoice_info.invoice_email,
				});
			});
	};

	const updateInvoiceInfo = () => {
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${apiKey}`,
			},
			body: JSON.stringify(formData),
		};

		const url = `${process.env.REACT_APP_API_URL}/v1/invoice-info`;
		fetch(url, options)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				getInvoiceInfo();

				setInvoicingEdit(false);
			});
	};

	const getContact = () => {
		const url = `${process.env.REACT_APP_API_URL}/v1/contact?api_key=${apiKey}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				setContactData({
					email: data.contact.email,
					phone: data.contact.phone,
				});
			});
	};

	const updateContact = () => {
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${apiKey}`,
			},
			body: JSON.stringify(contactData),
		};

		const url = `${process.env.REACT_APP_API_URL}/v1/contact`;
		fetch(url, options)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				getContact();

				setContactEdit(false);
			});
	};

	const updateNote = () => {
		searchParams.set('note', note);
		setSearchParams(searchParams);
		setNoteEdit(false);
	};

	const updateCoupon = (coupon) => {
		searchParams.set('coupon', coupon);
		setSearchParams(searchParams);
		setCouponEdit(false);
	};

	const updateDate = () => {
		searchParams.set('begin', date);
		setSearchParams(searchParams);
		setDateEdit(false);
	};

	const generateDates = () => {
		Date.prototype.addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};

		if (isNaN(parseInt(URL_frequency))) {
			return [];
		}

		const N = Math.floor(90 / URL_frequency); // Calculate N
		const dates = [];

		// Start date as a Date object
		let currentDate = new Date(date);

		for (let i = 0; i < N; i++) {
			// Format date and push to array
			const formattedDate = currentDate.toISOString().split('T')[0] + ' ' + currentDate.toTimeString().split(' ')[0];
			dates.push(formattedDate);

			currentDate = currentDate.addDays(parseInt(URL_frequency));
		}

		return dates.map((date) => new Date(date).toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', year: 'numeric' }));
	};

	const submit = () => {
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${apiKey}`,
			},
			body: JSON.stringify({
				apartment_id: URL_apartment_id,
				begin: date,
				duration: URL_duration,
				addon_method: URL_addon_method,
				note: note,
				coupon: URL_coupon,
				frequency: URL_frequency,
			}),
		};

		const url = `${process.env.REACT_APP_API_URL}/v1/orders`;
		fetch(url, options)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				navigate(`/objednavka-odeslana?order_id=${data.order_id}&begin=${date}&repeating=${URL_frequency ? 1 : 0}&price=${data.total_price}`);
			});
	};

	return (
		<>
			<ContentWrapper hideButtons={true} maxWidth={'md'} showFAQ={false}>
				{/* Controls */}
				{isOriginal && (
					<section>
						<div className="flex-between gutter-20">
							<ActionButton onClick={() => navigate('/dalsi-objednavka')} icon="left" color="muted" label="Zpět" position="left" />
						</div>
					</section>
				)}
				{/* Intro */}
				<section>
					<LayoutCard>
						<Typography variant="h3" className="gutter-20">
							Shrnutí
						</Typography>
						<Typography variant="body1" className="gutter-20">
							Zkontrolujte, prosím, zda je všechno v pořádku. {isOriginal && <>Pro změny se vraťte na formulář tlačítkem zpět.</>}
						</Typography>
					</LayoutCard>
				</section>
				{/* Apartment */}
				<section>
					<LayoutCard>
						<Typography variant="h3" className="gutter-20">
							Domácnost
						</Typography>
						<Typography variant="body1" className="gutter-20" component="div">
							<table style={{ width: '100%' }}>
								<tbody>
									{[
										{ label: 'Adresa', value: apartment?.apartment_address },
										{ label: 'Město', value: apartment?.apartment_town },
										{ label: 'PSČ', value: apartment?.apartment_zip },
									]
										.filter(({ value }) => !isFalsy(value))
										.map(({ label, value }) => (
											<>
												<Desktop>
													<tr key={label}>
														<td className="label">{label}</td>
														<td>{value}</td>
													</tr>
												</Desktop>
												<Mobile>
													<tr key={label}>
														<td className="label">{label}</td>
													</tr>
													<tr>
														<td>{value}</td>
													</tr>
												</Mobile>
											</>
										))}
								</tbody>
							</table>
						</Typography>
					</LayoutCard>
				</section>
				{/* Order */}
				<section>
					<LayoutCard>
						<div className="flex-between gutter-20">
							<Typography variant="h3">
								<T translationKey="Objednávka" />
							</Typography>
							{!dateEdit && (
								<ActionButton label={'Změnit datum'} color="muted" variant="contained" icon={'edit'} onClick={() => setDateEdit(true)} />
							)}
						</div>
						<Typography variant="body1" className="gutter-20" component="div">
							{dateEdit && (
								<>
									<ChooseDate setDate={setDate} date={date} regionId={apartment?.zone?.region_id} hours={URL_duration} withoutCard={true} />
									<div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
										<ActionButton label={'Uložit datum'} color="primary" variant="contained" onClick={updateDate} />
									</div>
								</>
							)}
							<table style={{ width: '100%' }}>
								<tbody>
									{[
										{
											label: 'Termín úklidu',
											value: formatSchedule(date, URL_duration),
											show: !dateEdit,
										},
										{
											label: 'Data opakování úklidů',
											value: <div dangerouslySetInnerHTML={{ __html: generateDates().slice(0, 7).join(', ') + '...' }} />,
											show: generateDates().length > 0 && !dateEdit,
										},
										{
											label: '',
											value: t('+ další datumy v zadané frekvenci'),
											show: generateDates().length > 0 && !dateEdit,
										},
									]
										.filter(({ value }) => !isFalsy(value))
										.filter(({ show }) => show)
										.map(({ label, value }) => (
											<>
												<Desktop>
													<tr key={label}>
														<td className="label">{label}</td>
														<td>{value}</td>
													</tr>
												</Desktop>
												<Mobile>
													<tr key={label}>
														<td className="label">{label}</td>
													</tr>
													<tr>
														<td>{value}</td>
													</tr>
												</Mobile>
											</>
										))}
								</tbody>
							</table>
						</Typography>
					</LayoutCard>
				</section>
				{/* Note */}
				<section>
					<LayoutCard>
						<div className="flex-between gutter-20">
							<Typography variant="h3">
								<T translationKey="Poznámka" />
							</Typography>
							{!noteEdit && (
								<ActionButton label={'Upravit poznámku'} color="muted" variant="contained" icon={'edit'} onClick={() => setNoteEdit(true)} />
							)}
						</div>
						{noteEdit ? (
							<>
								<textarea
									type="text"
									className="input-field gutter-10"
									placeholder={'Zde vložte poznámku'}
									rows={note.split('\n').length + 1}
									onChange={(e) => {
										setNote(e.target.value);
										console.log(e);
									}}
								>
									{note}
								</textarea>
								<div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
									<ActionButton label={'Uložit poznámku'} color="primary" variant="contained" onClick={updateNote} />
								</div>
							</>
						) : (
							<Typography variant="body1">
								<div
									dangerouslySetInnerHTML={{
										__html: (URL_note == '' ? t('Nezadali jste žádnou poznámku') : URL_note).replaceAll('\n', '<br />'),
									}}
								/>
							</Typography>
						)}
					</LayoutCard>
				</section>
				{/* Invoicing */}
				<section>
					<LayoutCard>
						<div className="flex-between gutter-20">
							<Typography variant="h3">
								<T translationKey="Fakturační údaje" />
							</Typography>
							{!invoicingEdit && (
								<ActionButton label={'Změnit údaje'} color="muted" variant="contained" icon={'edit'} onClick={() => setInvoicingEdit(true)} />
							)}
						</div>
						<Typography variant="body1" className="gutter-20" component="div">
							<table style={{ width: '100%' }}>
								<tbody>
									{[
										{
											label: 'Jméno / Název subjektu',
											value: invoiceInfo?.invoice_info_name,
											name: 'name',
										},
										{
											label: 'Adresa',
											value: invoiceInfo?.invoice_info_street,
											name: 'street',
										},
										{
											label: 'Poštovní směrovací číslo',
											value: invoiceInfo?.invoice_info_postal_code,
											name: 'postal_code',
										},
										{
											label: 'Město / Obec',
											value: invoiceInfo?.invoice_info_city,
											name: 'city',
										},
										{
											label: 'IČ',
											value: invoiceInfo?.invoice_info_ico,
											name: 'ico',
										},
										{
											label: 'DIČ',
											value: invoiceInfo?.invoice_info_dic,
											name: 'dic',
										},
										{
											label: 'Fakturační email',
											value: invoiceInfo?.invoice_email,
											name: 'email',
										},
									]
										// .filter(({ value }) => !isFalsy(value))
										.map(({ label, value, name }) => (
											<>
												<Desktop>
													<tr key={label}>
														<td className="label">{label}</td>
														{invoicingEdit ? (
															<input
																type="text"
																name={name}
																className="input-field gutter-10"
																placeholder={label}
																value={formData[name]}
																onChange={(e) => setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }))}
															/>
														) : (
															<td>{value}</td>
														)}
													</tr>
												</Desktop>
												<Mobile>
													<tr key={label}>
														<td className="label">{label}</td>
													</tr>
													<tr>
														{invoicingEdit ? (
															<input
																type="text"
																name={name}
																className="input-field gutter-10"
																placeholder={label}
																value={formData[name]}
																onChange={(e) => setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }))}
															/>
														) : (
															<td>{value}</td>
														)}
													</tr>
												</Mobile>
											</>
										))}
								</tbody>
							</table>
							{invoicingEdit && (
								<div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
									<ActionButton label={'Uložit údaje'} color="primary" variant="contained" onClick={updateInvoiceInfo} />
								</div>
							)}
						</Typography>
					</LayoutCard>
				</section>
				<section>
					<LayoutCard>
						<div className="flex-between gutter-20">
							<Typography variant="h3">
								<T translationKey="Kontaktní údaje" />
							</Typography>
							{!contactEdit && (
								<ActionButton label={'Změnit kontakt'} color="muted" variant="contained" icon={'edit'} onClick={() => setContactEdit(true)} />
							)}
						</div>
						<Typography variant="body1" className="gutter-20" component="div">
							<table style={{ width: '100%' }}>
								<tbody>
									{[
										{
											label: 'Email',
											value: contactData?.email,
											name: 'email',
										},
										{
											label: 'Telefon',
											value: contactData?.phone,
											name: 'phone',
										},
									]
										// .filter(({ value }) => !isFalsy(value))
										.map(({ label, value, name }) => (
											<>
												<Desktop>
													<tr key={label}>
														<td className="label">{label}</td>
														{contactEdit ? (
															<input
																type="text"
																name={name}
																className="input-field gutter-10"
																placeholder={label}
																value={contactData[name]}
																onChange={(e) =>
																	setContactData((contactData) => ({ ...contactData, [e.target.name]: e.target.value }))
																}
															/>
														) : (
															<td>{value}</td>
														)}
													</tr>
												</Desktop>
												<Mobile>
													<tr key={label}>
														<td className="label">{label}</td>
													</tr>
													<tr>
														{contactEdit ? (
															<input
																type="text"
																name={name}
																className="input-field gutter-10"
																placeholder={label}
																value={contactData[name]}
																onChange={(e) =>
																	setContactData((contactData) => ({ ...contactData, [e.target.name]: e.target.value }))
																}
															/>
														) : (
															<td>{value}</td>
														)}
													</tr>
												</Mobile>
											</>
										))}
								</tbody>
							</table>
							{contactEdit && (
								<div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
									<ActionButton label={'Uložit kontakt'} color="primary" variant="contained" onClick={updateContact} />
								</div>
							)}
						</Typography>
					</LayoutCard>
				</section>
				{/* Coupon */}
				<section>
					<LayoutCard>
						<Typography variant="h3" className="gutter-20">
							Slevový kód nebo poukázka
						</Typography>
						<VerifyCoupon
							coupon={coupon}
							setCoupon={(coupon) => {
								setCoupon(coupon);
								updateCoupon(coupon);
							}}
						/>
					</LayoutCard>
				</section>
				{/* Pricing */}
				<section>
					<PriceOverlay
						zoneId={apartment?.zone?.zone_id ?? 1}
						duration={URL_duration}
						services={URL_addon_method ? [10001] : []}
						coupon={URL_coupon}
						mode={'summary'}
					/>
				</section>
				<div className="flex-mobile-column gap-10-20">
					{/* {isOriginal ? <ActionButton label={'Sdílet objednávku'} color="secondary" textToCopy={window.location.href} /> : <div />} */}
					<div />
					<ActionButton label={'Dokončit objednávku'} color="primary" onClick={submit} />
				</div>
				{/* <Alert severity="info" style={{ borderRadius: 16, marginTop: 20 }}>
					<T
						translationKey={
							'Pošlete objednávku na kontrolu své drahé polovičce nebo jiné osobě se kterou objednáváte. Ve shrnutí lze změnit: termín, poznámku, fakturační údaje a kontakt na vás. Nakonec může druhá osoba i objednávku zaplatit a zůstane přihlášená do vašeho účtu.'
						}
					/>
				</Alert> */}
			</ContentWrapper>
		</>
	);
};

function formatSchedule(begin, duration) {
	// const days = ['V pondělí', 'V úterý', 'Ve středu', 'V čtvrtek', 'V pátek', 'V sobotu', 'V neděli'];
	const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'];

	const dayOfWeekIndex = new Date(begin).getDay();
	const day = days[dayOfWeekIndex];

	const date = new Date(begin);
	const endTime = new Date(begin);
	endTime.setHours(date.getHours() + Math.floor(duration));
	endTime.setMinutes(date.getMinutes() + Math.round((duration % 1) * 60));

	return `${day} ${date.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', year: 'numeric' })} | ${date.toLocaleTimeString('cs-CZ', {
		hour: '2-digit',
		minute: '2-digit',
	})} - ${endTime.toLocaleTimeString('cs-CZ', { hour: '2-digit', minute: '2-digit' })}`;
}

const isFalsy = (value) =>
	!value || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && value !== null && Object.keys(value).length === 0);

export default NextOrderCheckout;
