import { Navigate, Outlet } from 'react-router-dom';
import useApiKey from '../../hooks/useApiKey';
import { useEffect, useState } from 'react';
import LoadingPage from '../LoadingPage';

const ProtectedRoutes = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const { apiKey, loading } = useApiKey();

	useEffect(() => {
		console.log('protected reload')
		if (loading) return;

		if (apiKey) {
			setIsAuthenticated(true);
		} else {
			setIsAuthenticated(false);
		}
	}, [apiKey, loading]);

	if (loading || isAuthenticated === null) {
		return <LoadingPage />;
	}

	return isAuthenticated ? <Outlet /> : <Navigate to="/prihlaseni" />;
};

export default ProtectedRoutes;
