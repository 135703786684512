import React from 'react';
import { Mobile, Default } from '../lib/Responsive';
import PriceOverlay from '../PriceOverlayV2';

const PriceOverlayResponsive = ({ zoneId, duration = 3, service_ids = [], coupon = '', setMinTimeError }) => {
	return (
		<div style={{ height: '100%' }}>
			<Mobile>
				<div style={{ position: 'relative' }}>
					<PriceOverlay zoneId={zoneId} duration={duration} services={service_ids} coupon={coupon} setMinTimeError={setMinTimeError} />
				</div>
			</Mobile>
			<Default>
				<div
					style={{
						position: 'sticky',
						top: 95 + 5,
						width: 350,
					}}
				>
					<PriceOverlay zoneId={zoneId} duration={duration} services={service_ids} coupon={coupon} setMinTimeError={setMinTimeError} mode={'summary'} />
				</div>
			</Default>
		</div>
	);
};

export default PriceOverlayResponsive;
