import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import useIsMobile from '../lib/Responsive';

function MainTheme(props) {
	const isMobile = useIsMobile();
	const desktopTheme = createTheme({
		palette: {
			primary: {
				main: '#8c5cbc',
			},
		},
		typography: {
			display: 'block',
			fontFamily: 'graphik_regular, Arial',
			fontSize: 16,
			color: '#004D58',
			body0: {
				fontFamily: 'graphik_regular, Arial',
				fontSize: 20,
				color: '#004D58',
			},
			body1: {
				fontFamily: 'graphik_regular, Arial',
				fontSize: 16,
				color: '#004D58',
			},
			body2: {
				fontFamily: 'graphik_regular, Arial',
				fontSize: 14,
				color: '#004D58',
			},
			h1: {
				fontFamily: 'agrandir, Arial',
				fontSize: 64,
				color: '#004D58',
				textAlign: 'left',
			},
			h2: {
				fontFamily: 'agrandir, Arial',
				fontSize: 48,
				color: '#004D58',
				textAlign: 'left',
			},
			h3: {
				fontFamily: 'graphik_medium, Arial',
				fontSize: 24,
				color: '#004D58',
				textAlign: 'left',
			},
			h4: {
				fontFamily: 'graphik_medium, Arial',
				fontSize: 16,
				color: '#004D58',
				textAlign: 'left',
			},
		},
	});

	const mobileTheme = createTheme({
		palette: {
			primary: {
				main: '#8c5cbc',
			},
		},
		typography: {
			display: 'block',
			fontFamily: 'graphik_regular, Arial',
			fontSize: 14,
			color: '#004D58',
			body0: {
				fontFamily: 'graphik_regular, Arial',
				fontSize: 18,
				color: '#004D58',
			},
			body1: {
				fontFamily: 'graphik_regular, Arial',
				fontSize: 14,
				color: '#004D58',
			},
			body2: {
				fontFamily: 'graphik_regular, Arial',
				fontSize: 12,
				color: '#004D58',
			},
			h1: {
				fontFamily: 'agrandir, Arial',
				fontSize: 36,
				color: '#004D58',
				textAlign: 'center',
			},
			h2: {
				fontFamily: 'agrandir, Arial',
				fontSize: 28,
				color: '#004D58',
				textAlign: 'center',
			},
			h3: {
				fontFamily: 'graphik_medium, Arial',
				fontSize: 18,
				color: '#004D58',
			},
			h4: {
				fontFamily: 'graphik_medium, Arial',
				fontSize: 14,
				color: '#004D58',
				textAlign: 'left',
			},
		},
	});
	return <ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>{props.children}</ThemeProvider>;
}

export default MainTheme;
