import React, { useEffect, useRef, useState } from 'react';
import LayoutCard from '../Components/LayoutCard';
import { Alert, Collapse, Typography } from '@mui/material';
import useLang from '../hooks/useLang';
import useApiKey from '../hooks/useApiKey';
import usePersistentState from '../hooks/usePersistentState';
import ActionButton from './ActionButton';
import useIsMobile from '../lib/Responsive';

const ChooseDate = ({ date, setDate, hours, regionId, withoutCard = false, dateError = false, dateArb, DAY_COUNT = 5 }) => {
	const { T, t } = useLang('cs');
	const { apiKey } = useApiKey();
	const isMobile = useIsMobile();

	if (isMobile) {
		DAY_COUNT = 3;
	}

	const [dayOffset, setDayOffset] = usePersistentState('day_offset', 0);

	const [capacities, setCapacities] = usePersistentState('capacities', []);
	const [loadingCapacities, setLoadingCapacities] = useState(false);

	const [chosenSlot, setChosenSlot] = usePersistentState('chosen_slot', null);

	const isFirstRender = useRef(true);
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return; // Prevent running on mount
		}

		setChosenSlot(null);
		setDate(null);
	}, [dateArb]);

	useEffect(() => {
		getCapacities();
	}, [hours, regionId, dayOffset]);

	const getCapacities = () => {
		setLoadingCapacities(true);

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${apiKey}`,
		};

		const params = {
			// date: month.format('YYYY-MM-DD'),
			// order_id: props.order.order_id,

			duration: hours,
			region_id: regionId,
			days: generateDaysArray(dayOffset, DAY_COUNT),
		};

		const queryString = new URLSearchParams(
			Object.entries(params).flatMap(
				([key, value]) =>
					Array.isArray(value)
						? value.map((v) => [`${key}[]`, v]) // Flatten arrays into key-value pairs
						: [[key, value]] // Keep single values as key-value pairs
			)
		).toString();

		const url = `${process.env.REACT_APP_API_URL}/v1/capacity?${queryString}`;
		fetch(url, { headers: headers })
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				setCapacities(data.capacities);
				setLoadingCapacities(false);
			});
	};

	const generateDaysArray = (dayOffset, dayCount) => {
		const today = new Date();
		const startDay = new Date(today);
		startDay.setDate(today.getDate() + dayOffset); // Adjust for the offset

		return Array.from({ length: dayCount }, (_, i) => {
			const date = new Date(startDay);
			date.setDate(startDay.getDate() + i);
			return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
		});
	};

	const weekDays = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'];
	const weekDaysShort = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'];

	return (
		<LayoutCard omit={withoutCard}>
			{!withoutCard && (
				<>
					<div className="flex-between gutter-20">
						<Typography variant="h3">
							<T translationKey="Termín úklidu" />
						</Typography>
					</div>
					<Typography variant="body1">
						<T translationKey="Začněte výběrem data a času prvního úklidu." />
					</Typography>
				</>
			)}
			<Collapse in={regionId == null}>
				<Alert
					severity="info"
					style={{
						borderRadius: '16px',
						marginBottom: 8,
					}}
				>
					Pro zobrazení volých termínů zvolte domácnost.{' '}
					{!withoutCard && (
						<a href="#domacnost" style={{ textDecoration: 'underline' }}>
							Jak zvolit domácnost?
						</a>
					)}
				</Alert>
			</Collapse>
			<Collapse in={!loadingCapacities && regionId != null}>
				<div className="flex-between no-wrap gap-20 gutter-20">
					{dayOffset > 0 ? (
						<ActionButton
							onClick={() => setDayOffset(dayOffset - DAY_COUNT)}
							color="secondary"
							label={t('Předchozí')}
							icon="left"
							position="left"
						/>
					) : (
						<div />
					)}
					{dayOffset < 14 ? (
						<ActionButton onClick={() => setDayOffset(dayOffset + DAY_COUNT)} color="secondary" label={t('Další')} icon="right" />
					) : (
						<div />
					)}
				</div>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(' + capacities.length + ', 1fr)',
						gridGap: 4,
						overflowX: 'auto',
						width: '100%',
						maxWidth: '100%',
					}}
				>
					{capacities.map((day, index) => (
						<div style={{ minWidth: 'max-content' }}>
							<Typography style={{ padding: '0 8px', textAlign: 'center' }}>
								{weekDaysShort[new Date(day.date).getDay()]} (
								{new Date(day.date).toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric' })})
							</Typography>
							{day.slots.length <= 0 && (
								<div
									className="text-center"
									style={{
										backgroundColor: '#FF4747',
										padding: '2px 6px',
										margin: '2px 0',
										borderRadius: '4px',
									}}
								>
									<Typography variant="body1" className="drop-shadow" style={{ color: '#fff' }}>
										Nedostupné
									</Typography>
								</div>
							)}
							{day.slots.map((slot, jndex) => (
								<div
									className="text-center cursor-pointer text-white"
									style={{
										backgroundColor: chosenSlot == slot.id ? '#FFAF24' : '#458F00',
										// backgroundColor: '#458F00',
										padding: '2px 6px',
										margin: '2px 0',
										borderRadius: '4px',
									}}
									onClick={() => {
										setChosenSlot(slot.id);
										setDate(`${day.date} ${slot.from_formatted}:00`);
									}}
									// onClick={chooseDay}
									// time={formatTime(slot)}
									// day={weekDaysShort[new Date(day_capacity.date).getDay()]}
									// id={slot.id}
								>
									<Typography
										variant="body1"
										className="drop-shadow"
										// time={formatTime(slot)}
										// day={weekDaysShort[new Date(day_capacity.date).getDay()]}
										// id={slot.id}
										style={{ color: '#fff' }}
									>
										{(slot.start + ':00')?.replace('.5:00', ':30')}
									</Typography>
								</div>
							))}
						</div>
					))}
				</div>
				{dateError && (
					<Alert severity="error" style={{ borderRadius: 100, marginTop: 20 }}>
						Pro dokončení objednávky je nutné zvolit termín úklidu.
					</Alert>
				)}
			</Collapse>
		</LayoutCard>
	);
};

export default ChooseDate;
