import { useMediaQuery } from "react-responsive";

function useIsMobile() {
	return useMediaQuery({ maxWidth: 767 });
}

// for class components
export function IsMobile() {
	return useMediaQuery({ maxWidth: 767 });
}

export const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	return isMobile ? children : null
}

export const Default = ({ children }) => {
	const isNotMobile = useMediaQuery({ minWidth: 768 })
	return isNotMobile ? children : null
}

export const Desktop = ({ children }) => {
	const isNotMobile = useMediaQuery({ minWidth: 768 })
	return isNotMobile ? children : null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default useIsMobile;