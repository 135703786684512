import React, { useRef, useState } from 'react';
import { clearPersistentStates } from '../hooks/usePersistentState';
import ActionButton from './ActionButton';
import useLang from '../hooks/useLang';
import '../styles/layout.css';
import { Typography } from '@mui/material';
import '../styles/Dialog.css';
import { DialogCard } from './LayoutCard';

const PersistentStateClearDialog = ({ refresh }) => {
	const { T, t } = useLang('cs');
	const [dialogOpen, setDialogOpen] = useState(false);
	const dialogRef = useRef(null);

	const openDialog = () => {
		setDialogOpen(true);
		dialogRef.current.showModal();
	};

	const closeDialog = () => {
		setDialogOpen(false);
		dialogRef.current.close();
	};

	const handleClear = () => {
		clearPersistentStates();
		closeDialog();
		refresh();
	};

	return (
		<div>
			<ActionButton onClick={openDialog} icon="trash" color="muted" label="" />

			<DialogCard refPass={dialogRef} className="dialog" onClick={closeDialog}>
				<div className="dialog-content" onClick={(e) => e.stopPropagation()}>
					<Typography className="gutter-20">
						<T translationKey="persistent_are_you_sure" />
					</Typography>

					<div className="flex-between gap-10-20">
						<ActionButton label={t('persistent_continue')} color="secondary" onClick={closeDialog} />
						<ActionButton label={t('persistent_confirm')} color="danger" onClick={handleClear} icon="trash" />
					</div>
				</div>
			</DialogCard>
		</div>
	);
};

export default PersistentStateClearDialog;
