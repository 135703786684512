import React, { useEffect, useMemo, useState } from 'react';
import usePersistentState from '../hooks/usePersistentState';
import useLang from '../hooks/useLang';
import DropdownSelect from '../Components/DropdownSelect';
import '../styles/layout.css';
import '../styles/NextOrderForm.css';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Alert, Collapse, FormControlLabel, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import PersistentStateClearDialog from '../Components/PersistentStateClearDialog';
import useApiKey from '../hooks/useApiKey';
import LayoutCard from '../Components/LayoutCard';
import CreateApartmentDialog from '../Components/CreateApartmentDialog';
import GotoProfileDialog from '../Components/GotoProfileDialog';
import TimeCalculator from '../Components/TimeCalculator';
import PriceOverlayResponsive from '../Components/PriceOverlayResponsive';
import ChooseDate from '../Components/ChooseDate';
import PriceOverlay from '../PriceOverlay';
import ActionButton from '../Components/ActionButton';
import { Verified } from '@mui/icons-material';
import VerifyCoupon from '../Components/VerifyCoupon';

const NextOrderForm = () => {
	const { T, t } = useLang('cs');
	const { apiKey } = useApiKey();

	// user journey steps and variants

	// order data
	const [hours, setHours] = usePersistentState('hours', 4);
	const [apartmentId, setApartmentId] = usePersistentState('apartment_id', null);
	const [zoneId, setZoneId] = usePersistentState('zone_id', null);
	const [regionId, setRegionId] = usePersistentState('region_id', null);
	const [selectedExtras, setSelectedExtras] = usePersistentState('selected_extras', []);
	const [date, setDate] = usePersistentState('date', null);
	const [dateArb, setDateArb] = useState(null);
	const [frequency, setFrequency] = usePersistentState('frequency', 14);
	const [frequencyCarry, setFrequencyCarry] = usePersistentState('frequency_carry', 14); // useful for keeping frequency while disabled
	const [note, setNote] = usePersistentState('note', '');
	const [apartmentIdEdited, setApartmentIdEdited] = usePersistentState('apartment_id_edited', false);
	const [noteEdited, setNoteEdited] = usePersistentState('note_edited', false);
	const [coupon, setCoupon] = usePersistentState('coupon', '');

	// variant calculator
	const [inhabitants, setInhabitants] = usePersistentState('inhabitants', 1);
	const [bathrooms, setBathrooms] = usePersistentState('bathrooms', 1);

	const [calculator, setCalculator] = usePersistentState('journey_calculator', false);

	// api data
	const [apartments, setApartments] = useState([]);
	const [extras, setExtras] = useState([]);

	useEffect(() => {
		if (!calculator) return;

		const min = 3; //shortest service can take 3h

		const bathroom_table = {
			1: 40 / 60,
			2: 120 / 60,
			3: 160 / 60,
		};

		const extra_duration = extras
			.filter((service) => {
				return selectedExtras.includes(service.id);
			})
			.reduce((sum, service) => sum + service.estimate, 0);
		const sum = 1 + (inhabitants * 40) / 60 + bathroom_table[bathrooms] + extra_duration ?? 0;
		const rounded = Math.ceil(sum * 2) / 2; // ceil on x.5h
		const recommended = Math.max(min, rounded);
		setHours(recommended);
	}, [inhabitants, bathrooms, selectedExtras]);

	useEffect(() => {
		if (apartmentId && apartmentId != -1) {
			const selectedApartment = apartments.find((apartment) => apartment.apartment_id === apartmentId);
			if (selectedApartment) {
				setHours(selectedApartment?.duration || 4);
				setZoneId(selectedApartment?.zone?.zone_id || null);
				setRegionId(selectedApartment?.zone?.region_id || null);
				if (!noteEdited) {
					// setNote(selectedApartment?.note || '');		//* chaining bug
				}
			} else {
				setZoneId(null);
				setRegionId(null);
			}
		} else {
			setZoneId(null);
			setRegionId(null);
		}
	}, [apartmentId, apartments]);

	useEffect(() => {
		getApartments();
	}, [apiKey]);

	const getApartments = () => {
		const url = `${process.env.REACT_APP_API_URL}/v1/apartments?api_key=${apiKey}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				setApartments(data.apartments);
				setApartmentId(data.apartments[0]?.apartment_id);
				data.apartments.forEach((apartment) => {
					if (apartment.has_last_finished_order && !apartmentIdEdited) {
						setApartmentId(apartment.apartment_id);
						setHours(apartment.duration);
						return;
					}
				});
			});
	};

	const apartmentCreated = (zoneId, apartmentId) => {
		setApartmentId(apartmentId);
		getApartments();
		setZoneId(zoneId);
	};

	const refresh = () => {
		getApartments();
	};

	const generateDates = () => {
		Date.prototype.addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};

		if (isNaN(parseInt(frequency))) {
			return [];
		}

		const N = Math.floor(90 / frequency); // Calculate N
		const dates = [];

		// Start date as a Date object
		let currentDate = new Date(date);

		for (let i = 0; i < N; i++) {
			// Format date and push to array
			const formattedDate = currentDate.toISOString().split('T')[0] + ' ' + currentDate.toTimeString().split(' ')[0];
			dates.push(formattedDate);

			currentDate = currentDate.addDays(parseInt(frequency));
		}

		return dates;
	};

	const generateShareLink = (includeApiKey) => {
		//* 'user_id' => 'required|integer',	// = apiKey
		//* 'client_id' => 'required|integer',	// = apiKey
		//* 'apartment_id' => 'required|integer',
		//* 'begin' => 'required|date|date_format:Y-m-d H:i:s',
		//* 'duration' => 'required|numeric',
		//* 'addon_method' => 'boolean',
		//* 'note' => 'string',
		//* 'coupon' => 'string',
		//* 'frequency' => 'integer',

		const params = new URLSearchParams({
			apartment_id: apartmentId,
			begin: date,
			duration: hours,
			addon_method: selectedExtras.includes(10001) ? 1 : 0,
			note: note,
			coupon: coupon,
			frequency: frequency ? frequency : -1,
		});

		if (includeApiKey) {
			params.append('api_key', apiKey);
		}

		return `${window.location.origin}/shrnuti-objednavky?${params.toString()}`;
	};

	const [fixErrors, setFixErrors] = useState(false);
	const [apartmentError, setApartmentError] = useState(false);
	const [dateError, setDateError] = useState(false);
	const [minTimeError, setMinTimeError] = useState(false);

	const validationOk = () => {
		const validationOk = apartmentId != null && apartmentId != -1 && date != null && !minTimeError;
		return validationOk;
	};

	useEffect(() => {
		if (fixErrors) {
			setApartmentError(apartmentId == null || apartmentId == -1);
			setDateError(date == null);
		}
	}, [date, apartmentId, fixErrors]);

	return (
		<>
			<ContentWrapper hideButtons={true} maxWidth={'lg'} showFAQ={false}>
				{/* Controls */}
				<section>
					<div className="flex-between gutter-20">
						<GotoProfileDialog />
						<PersistentStateClearDialog refresh={refresh} />
					</div>
				</section>
				<div className="next-order-form">
					<div className="form-aside">
						<PriceOverlayResponsive
							zoneId={zoneId}
							duration={hours}
							service_ids={selectedExtras}
							coupon={coupon}
							setMinTimeError={setMinTimeError}
						/>
					</div>
					<div className="form-body">
						{/* Apartment */}
						<section>
							<div id="domacnost" style={{ position: 'relative', top: -100 }} />
							<LayoutCard>
								<div className="flex-between gutter-20">
									<Typography variant="h3">
										<T translationKey="apartment" />
									</Typography>
								</div>
								<div className="grid-2-col-asymmetric gap-20">
									<DropdownSelect
										label={t('apartment_label')}
										placeholder={t('apartment_placeholder')}
										value={apartmentId ?? apartments[0]?.apartment_id}
										setValue={(value) => {
											setApartmentId(value);
											setApartmentIdEdited(true);
											const random = Math.random();
											setDateArb(random);
											console.log(random);
										}}
										options={apartments.map((apartment) => ({
											label: `${apartment.apartment_address} (${apartment.finished_orders} úklidů)`,
											value: apartment.apartment_id,
										}))}
									/>
									<CreateApartmentDialog apartmentCreated={apartmentCreated} />
								</div>
								{apartmentError && (
									<Alert severity="error" style={{ borderRadius: 100, marginTop: 20 }}>
										Prosím zvolte domácnost.
									</Alert>
								)}
							</LayoutCard>
						</section>
						{/* Duration and services */}
						<section>
							<TimeCalculator
								hours={hours}
								setHours={setHours}
								inhabitants={inhabitants}
								setInhabitants={setInhabitants}
								bathrooms={bathrooms}
								setBathrooms={setBathrooms}
								zoneId={zoneId}
								selectedExtras={selectedExtras}
								setSelectedExtras={setSelectedExtras}
								setParentExtras={setExtras}
								calculator={calculator}
								setCalculator={setCalculator}
							/>
						</section>
						{/* Frequency */}
						<section>
							<div id="termin" style={{ position: 'relative', top: -100 }} />
							<ChooseDate setDate={setDate} date={date} regionId={regionId} hours={hours} dateError={dateError} dateArb={dateArb} />
							<LayoutCard>
								<div className="flex-between gutter-20">
									<Typography variant="h3">
										<T translationKey="Opakování úklidu" />
									</Typography>
								</div>
								<Collapse in={!date}>
									<Alert severity="info" style={{ borderRadius: 16 }}>
										<T translationKey="Pro nastavení opakování si vyberte datum prvního úklidu." />
									</Alert>
								</Collapse>
								<Collapse in={date}>
									<div className="grid-1-col gap-20">
										<FormControlLabel
											control={
												<Switch
													checked={frequency != false}
													onChange={(e) => setFrequency(e.target.checked ? frequencyCarry : false)}
													color="primary"
												/>
											}
											label={t('Přeji si pravidelné úklidy.')}
										/>
										<Alert severity="info" style={{ borderRadius: 16 }}>
											<T translationKey="Volbou frekvence opakování nám umožníte přiřadit vám hospodyni, která má v kalendáři čas na první i budoucí úklidy. Následující úklid vždy platíte po dokončení předchozího." />
										</Alert>
										<Collapse in={frequency}>
											<Typography variant="body1" className="gutter-10">
												Opakování každých:
											</Typography>
											<RadioGroup
												value={frequency ?? frequencyCarry}
												onChange={(e) => {
													setFrequency(e.target.value);
													setFrequencyCarry(e.target.value);
												}}
												style={{ display: 'flex', flexDirection: 'column', gap: 10, margin: '20px 0' }}
											>
												<FormControlLabel
													value={7}
													control={<Radio />}
													label="Každých 7 dní - vhodné pro rodiny s dětmi a náročnější klienty"
												/>
												<FormControlLabel value={14} control={<Radio />} label="Každých 14 dní - vhodné pro běžné udržení čistoty" />
												<FormControlLabel
													value={28}
													control={<Radio />}
													label="Každých 28 dní - vhodné jako občasná výpomoc s méně častými úkony"
												/>
											</RadioGroup>
											<Typography variant="body1" className="gutter-10">
												Vámi naplánované úklidy budou:
												<ol>
													{generateDates().map((date, index) => (
														<>
															<li key={index} style={{ marginLeft: 10 }}>
																{new Intl.DateTimeFormat('cs-CZ', {
																	weekday: 'long',
																	day: 'numeric',
																	month: 'numeric',
																	year: 'numeric',
																	hour: '2-digit',
																	minute: '2-digit',
																})
																	.format(new Date(date))
																	.replace(',', '') // Remove the comma
																	.replace(/(\d{1,2}) (\d{1,2})\./, '$1. $2.') // Adjust spacing in the date
																	.replace(/(\d{2}:\d{2})/, 'v $1') // Add 'v' before the time
																	.replace(/^./, (char) => char.toUpperCase())}
															</li>
														</>
													))}
													<li style={{ marginLeft: 10 }}>
														<T translationKey={'+ další datumy v zadané frekvenci'} />
													</li>
												</ol>
											</Typography>
										</Collapse>
									</div>
								</Collapse>
							</LayoutCard>
						</section>
						{/* Note */}
						<section>
							<LayoutCard>
								<Typography variant="h3" className="gutter-20">
									Poznámka hospodyni
								</Typography>
								<Typography variant="body1" style={{ fontFamily: 'agrandir', color: '#004d58', marginBottom: 10 }}>
									Prosím, do poznámky uvádějte vaše požadavky, případně výstup z telefonické dohody.
								</Typography>
								<TextField
									label="Co byste nám ještě chtěli sdělit?"
									variant="outlined"
									minRows={4}
									className="w-full"
									style={{ width: '100%' }}
									name="note"
									onChange={(e) => {
										setNote(e.target.value);
										setNoteEdited(true);
									}}
									value={note}
									autoComplete="note"
									multiline
									InputLabelProps={{
										style: {
											color: 'rgba(0, 66, 80, 0.5)',
										},
									}}
								/>
							</LayoutCard>
						</section>
						{/* Summary */}
						<section>
							<LayoutCard>
								<Typography variant="h3" className="gutter-20">
									Slevový kód
								</Typography>
								<VerifyCoupon coupon={coupon} setCoupon={setCoupon} />
							</LayoutCard>
						</section>
						{/* Summary */}
						<section>
							<Collapse in={fixErrors}>
								{apartmentError && (
									<Alert severity="error" style={{ borderRadius: 100, marginBottom: 20 }}>
										Pro dokončení objednávky je nutné zvolit domácnost.{' '}
										<a href="#domacnost" style={{ textDecoration: 'underline' }}>
											Ukázat chybu
										</a>
									</Alert>
								)}
								{dateError && (
									<Alert severity="error" style={{ borderRadius: 100, marginBottom: 20 }}>
										Pro dokončení objednávky je nutné zvolit termín úklidu.{' '}
										<a href="#termin" style={{ textDecoration: 'underline' }}>
											Ukázat chybu
										</a>
									</Alert>
								)}
								{minTimeError && (
									<Alert severity="error" style={{ borderRadius: 100, marginBottom: 20 }}>
										Pro tuto lokalitu bohužel nemůžeme nabízet tuto délku úklidu.
									</Alert>
								)}
							</Collapse>
							<div className="flex-mobile-column gap-10-20">
								<ActionButton label={'Sdílet objednávku'} color="secondary" textToCopy={generateShareLink(true)} />
								{validationOk() ? (
									<a className="action-button button-primary" href={generateShareLink(false)}>
										<span className="action-button-label">Dokončit objednávku</span>
									</a>
								) : (
									<div className="action-button button-primary" onClick={() => setFixErrors(true)}>
										<span className="action-button-label">Dokončit objednávku</span>
									</div>
								)}
							</div>
							<Alert severity="info" style={{ borderRadius: 16, marginTop: 20 }}>
								<T
									translationKey={
										'Tlačítkem sdílejte objednávku a ukažte tak své drahé polovičce nebo jiné osobě co objednáváte. Ve shrnutí lze změnit: termín, poznámku, fakturační údaje a kontakt na vás. Nakonec může druhá osoba i objednávku zaplatit a zůstane přihlášená do vašeho účtu.'
									}
								/>
							</Alert>
						</section>
					</div>
				</div>
			</ContentWrapper>
		</>
	);
};

export default NextOrderForm;
