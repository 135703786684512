import React, { useState } from 'react';
import '../styles/ActionButton.css';

const ActionButton = ({ onClick, label, color = 'primary', icon = null, position = 'right', textToCopy = null }) => {
	const nbspLabel = label.replaceAll(' ', '\u00A0');

	const [copied, setCopied] = useState(false);

	const handleCopy = async () => {
		if (textToCopy) {
			try {
				await navigator.clipboard.writeText(textToCopy);
				setCopied(true);
				setTimeout(() => setCopied(false), 1500); // Reset after 1.5s
			} catch (err) {
				console.error('Failed to copy: ', err);
			}
		}
		if (onClick) onClick();
	};

	return (
		<div style={{ position: 'relative' }}>
			<button onClick={textToCopy ? handleCopy : onClick} className={`action-button button-${color}`}>
				{position == 'right' && <span className="action-button-label">{nbspLabel}</span>}
				<span className={`icon ${nbspLabel.length > 0 && 'icon-offset-' + position}`}>
					{icon && icon === 'trash' && <TrashIcon />}
					{icon && icon === 'close' && <CloseIcon />}
					{icon && icon === 'calculator' && <CalculatorIcon />}
					{icon && icon === 'close_calculator' && <CloseCalculatorIcon />}
					{icon && icon === 'profile' && <ProfileIcon />}
					{icon && icon === 'left' && <LeftIcon />}
					{icon && icon === 'right' && <RightIcon />}
					{icon && icon === 'copy' && <CopyIcon />}
					{icon && icon === 'edit' && <EditIcon />}
				</span>
				{position == 'left' && <span className="action-button-label">{nbspLabel}</span>}
			</button>

			<style>{`
			.action-button-wrapper {
				position: relative;
				display: inline-block;
			}

			.copy-popup {
				position: absolute;
				bottom: 110%;
				left: 50%;
				transform: translateX(-50%);
				background: rgba(0, 0, 0, 0.8);
				color: white;
				padding: 6px 12px;
				border-radius: 5px;
				font-size: 14px;
				white-space: nowrap;
				opacity: 0;
				animation: fadeInOut 1.5s ease-in-out;
				pointer-events: none;
			}

			@keyframes fadeInOut {
				0% {
					opacity: 0;
					transform: translateX(-50%) translateY(5px);
				}
				10% {
					opacity: 1;
					transform: translateX(-50%) translateY(0);
				}
				90% {
					opacity: 1;
					transform: translateX(-50%) translateY(0);
				}
				100% {
					opacity: 0;
					transform: translateX(-50%) translateY(-5px);
				}
			}
			`}</style>
			{/* Copy Notification Popup */}
			{copied && <div className="copy-popup">Odkaz zkopírován</div>}
		</div>
	);
};

const TrashIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth="1.5"
		width="20"
		height="20"
	>
		<path d="M4 7l16 0"></path>
		<path d="M10 11l0 6"></path>
		<path d="M14 11l0 6"></path>
		<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
		<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
	</svg>
);

const CloseIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth="2"
	>
		<path d="M18 6l-12 12" />
		<path d="M6 6l12 12" />
	</svg>
);

const CalculatorIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth="2"
	>
		<path d="M4 3m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
		<path d="M8 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
		<path d="M8 14l0 .01" />
		<path d="M12 14l0 .01" />
		<path d="M16 14l0 .01" />
		<path d="M8 17l0 .01" />
		<path d="M12 17l0 .01" />
		<path d="M16 17l0 .01" />
	</svg>
);

const CloseCalculatorIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		width="20"
		height="20"
		strokeWidth="2"
	>
		<path d="M19.823 19.824a2 2 0 0 1 -1.823 1.176h-12a2 2 0 0 1 -2 -2v-14c0 -.295 .064 -.575 .178 -.827m2.822 -1.173h11a2 2 0 0 1 2 2v11"></path>
		<path d="M10 10h-1a1 1 0 0 1 -1 -1v-1m3 -1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1"></path> <path d="M8 14v.01"></path> <path d="M12 14v.01"></path>
		<path d="M8 17v.01"></path> <path d="M12 17v.01"></path> <path d="M16 17v.01"></path> <path d="M3 3l18 18"></path>
	</svg>
);

const ProfileIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		width="20"
		height="20"
		strokeWidth="1.5"
	>
		<path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path> <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>{' '}
		<path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>{' '}
	</svg>
);

const LeftIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		width="24"
		height="24"
		strokeWidth="1"
	>
		<path d="M15 6l-6 6l6 6"></path>
	</svg>
);

const RightIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		width="24"
		height="24"
		strokeWidth="1"
	>
		<path d="M9 6l6 6l-6 6" />
	</svg>
);

const CopyIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		width="20"
		height="20"
		strokeWidth="1"
	>
		<path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
		<path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
	</svg>
);

const EditIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		width="24"
		height="24"
		strokeWidth="1"
	>
		<path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
		<path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path> <path d="M16 5l3 3"></path>
	</svg>
);

export default ActionButton;
