import React, { useEffect, useState } from 'react';
import usePersistentState from '../hooks/usePersistentState';
import { Alert, Collapse, Typography } from '@mui/material';
import LayoutCard from './LayoutCard';
import ActionButton from './ActionButton';
import MobileTooltip from '../MobileTooltip';
import useLang from '../hooks/useLang';
import NumberStepper from './NumberStepper';
import CheckBoxField from './CheckBoxField';

const TimeCalculator = ({
	hours,
	setHours,
	inhabitants,
	setInhabitants,
	bathrooms,
	setBathrooms,
	zoneId,
	selectedExtras,
	setSelectedExtras,
	setParentExtras,
	calculator,
	setCalculator,
}) => {

	return (
		<>
			<Collapse in={!calculator}>
				<TimeForm
					setCalculator={setCalculator}
					hours={hours}
					setHours={setHours}
					selectedExtras={selectedExtras}
					setSelectedExtras={setSelectedExtras}
				/>
			</Collapse>
			<Collapse in={calculator}>
				<Calculator
					setCalculator={setCalculator}
					inhabitants={inhabitants}
					setInhabitants={setInhabitants}
					bathrooms={bathrooms}
					setBathrooms={setBathrooms}
					zoneId={zoneId}
					selectedExtras={selectedExtras}
					setSelectedExtras={setSelectedExtras}
					setParentExtras={setParentExtras}
				/>
			</Collapse>
		</>
	);
};

const Calculator = ({ setCalculator, inhabitants, setInhabitants, bathrooms, setBathrooms, selectedExtras, setSelectedExtras, zoneId, setParentExtras }) => {
	const { T, t } = useLang('cs');
	const [extras, setExtras] = useState([]);
	const [servicesError, setServicesError] = useState(null);

	useEffect(() => {
		getServices();
	}, [zoneId]);

	const getServices = () => {
		setServicesError(null);
		setExtras([]);
		if (zoneId === null) {
			setServicesError('Pro zobrazení služeb nejprve vyberte domácnost. Od vaší lokality se odvíjí služby, které jsme schopni nabídnout.');
			return;
		}
		const url = `${process.env.REACT_APP_API_URL}/services?zone_id=${zoneId}&show_method=1`;

		fetch(url)
			.then((response) => {
				if (!response.ok) throw new Error('network'); // catch and show network error to user, prompt to refresh page
				return response.json();
			})
			.then((data) => {
				const services = Object.values(data).map((service) => {
					return {
						id: service.catalog_service_id,
						label: service.catalog_service_name,
						selected: false,
						estimate: service.catalog_service_estimate,
						description: service.catalog_service_checklist,
						is_extra: service.catalog_service_difficulty >= 10,
					};
				});

				setExtras(services);
			});
	};

	useEffect(() => {
		setParentExtras(extras);
	}, [extras]);

	return (
		<LayoutCard>
			<div className="flex-between gutter-20">
				<Typography variant="h3">
					<T translationKey="calculator" />
				</Typography>
				<div>
					<ActionButton onClick={() => setCalculator(false)} color="muted" label={t('close_calculator')} icon="close_calculator" />
				</div>
			</div>
			<div className="flex-start gap-60 gutter-20">
				<div>
					<Typography variant="body1" className="gutter-10">
						<MobileTooltip title={t('inhabitants_tooltip')} element="inline">
							<T translationKey="inhabitants" />
						</MobileTooltip>
					</Typography>
					<NumberStepper value={inhabitants} setValue={setInhabitants} min={1} max={6} step={1} postfix="" />
				</div>
				<div>
					<Typography variant="body1" className="gutter-10">
						<MobileTooltip title={t('bathrooms_tooltip')} element="inline">
							<T translationKey="bathrooms" />
						</MobileTooltip>
					</Typography>
					<NumberStepper value={bathrooms} setValue={setBathrooms} min={1} max={3} step={1} postfix="" />
				</div>
			</div>
			<div className="gutter-20">
				{servicesError !== null && (
					<Alert severity="info" style={{ borderRadius: 24 }}>
						{servicesError}
					</Alert>
				)}
				{zoneId !== null &&
					extras
						.sort((a, b) => (a.id === 10001 ? -1 : b.id === 10001 ? 1 : 0))
						.map((extra, index) => (
							<div key={index}>
								<CheckBoxField
									key={extra.id}
									label={extra.label}
									checked={selectedExtras.includes(extra.id)}
									setChecked={() => {
										setSelectedExtras((prev) => {
											if (selectedExtras.includes(extra.id)) {
												return prev.filter((serviceId) => serviceId !== extra.id);
											} else {
												return [...prev, extra.id];
											}
										});
									}}
								/>
								{extra.is_extra && <MobileTooltip title={t('Nadstandartní služba')} element="inline"><T translationKey=" * " /></MobileTooltip>}
							</div>
						))}
			</div>
			<Alert severity="info" style={{ borderRadius: 16 }} icon={'*'}>
				{t(
					'Tyto nadstandardní služby zajišťuje externí partner naší firmy. Do 72 hodin (od zaplacení objednávky) budete kontaktováni formou e-mailu, nebo telefonicky ohledně rozsahu, termínu a cenovém odhadu služby.'
				)}
			</Alert>
		</LayoutCard>
	);
};

const TimeForm = ({ hours, setHours, method, setMethod, setCalculator, selectedExtras, setSelectedExtras }) => {
	const { T, t } = useLang('cs');

	return (
		<LayoutCard>
			<div className="flex-between gutter-20">
				<Typography variant="h3">
					<T translationKey="services" />
				</Typography>
				<div>
					<ActionButton onClick={() => setCalculator(true)} label={t('open_calculator')} icon="calculator" />
				</div>
			</div>
			<div className="gutter-20">
				<Typography variant="body1" className="gutter-10">
					<MobileTooltip title={t('hours_tooltip')} element="inline">
						<T translationKey="hours" />
					</MobileTooltip>
				</Typography>
				<NumberStepper value={hours} setValue={setHours} min={3} max={10} step={0.5} postfix="h" />
			</div>
			<div className="gutter-20">
				<CheckBoxField
					key={10001}
					label={t('method')}
					checked={selectedExtras.includes(10001)}
					setChecked={() => {
						setSelectedExtras((prev) => {
							if (selectedExtras.includes(10001)) {
								return prev.filter((serviceId) => serviceId !== 10001);
							} else {
								return [...prev, 10001];
							}
						});
					}}
				/>
			</div>
		</LayoutCard>
	);
};

export default TimeCalculator;
