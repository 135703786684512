import React, { useRef, useState } from 'react';
import usePersistentState, { clearPersistentStates } from '../hooks/usePersistentState';
import ActionButton from './ActionButton';
import useLang from '../hooks/useLang';
import '../styles/layout.css';
import { Typography } from '@mui/material';
import '../styles/Dialog.css';
import { DialogCard } from './LayoutCard';
import { DropdownField } from '../LeadFormV3';
import useApiKey from '../hooks/useApiKey';
import NumberStepper from './NumberStepper';
import MobileTooltip from '../MobileTooltip';

const CreateApartmentDialog = ({ apartmentCreated }) => {
	const { T, t } = useLang('cs');
	const [dialogOpen, setDialogOpen] = useState(false);
	const dialogRef = useRef(null);

	const openDialog = () => {
		setDialogOpen(true);
		dialogRef.current.showModal();
	};

	const closeDialog = () => {
		setDialogOpen(false);
		dialogRef.current.close();
	};

	const handleClear = () => {
		clearPersistentStates();
		closeDialog();
	};

	// apartment functionality
	const [zoneId, setZoneId] = usePersistentState('dialog_zone_id', null);
	const [zoneLabel, setZoneLabel] = usePersistentState('zone_label', '');
	const [referenceZoneLabel, setReferenceZoneLabel] = usePersistentState('reference_zone_label', '');
	const [address, setAddress] = usePersistentState('dialog_address', '');
	const [zip, setZip] = usePersistentState('dialog_zip', '');
	const [streetNumber, setStreetNumber] = usePersistentState('dialog_street_number', '');
	const [inhabitants, setInhabitants] = usePersistentState('dialog_inhabitants', 1);
	const [bathrooms, setBathrooms] = usePersistentState('dialog_bathrooms', 1);

	const { apiKey } = useApiKey();

	const createApartment = () => {
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${apiKey}`,
			},
			body: JSON.stringify({
				zone_id: zoneId,
				address: `${address} ${streetNumber}`,
				city: referenceZoneLabel,
				zip: zip,
				residents: inhabitants,
				bathrooms: bathrooms,
			}),
		};

		const url = `${process.env.REACT_APP_API_URL}/v1/apartments`;
		fetch(url, options)
			.then((response) => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then((data) => {
				apartmentCreated(zoneId, data.apartment.apartment_id);

				setZoneId(null);
				setZoneLabel('');
				setReferenceZoneLabel('');
				setAddress('');
				setZip('');
				setStreetNumber('');

				closeDialog();
			});
	};

	return (
		<>
			<ActionButton label={t('Nová domácnost')} onClick={openDialog} color="primary" />
			<DialogCard refPass={dialogRef} className="dialog" onClick={closeDialog}>
				<div className="dialog-content" onClick={(e) => e.stopPropagation()}>
					<div className="flex-between gutter-20">
						<Typography variant="h3">
							<T translationKey="new_apartment" />
						</Typography>
						<div>
							<ActionButton onClick={() => closeDialog()} label={t('close')} icon="close" color="muted" />
						</div>
					</div>
					<div className="grid-2-mobile gap-20 gutter-20">
						<DropdownField
							zoneId={zoneId}
							setZoneId={setZoneId}
							zoneLabel={zoneLabel}
							setZoneLabel={setZoneLabel}
							setReferenceZoneLabel={setReferenceZoneLabel}
							referenceZoneLabel={referenceZoneLabel}
						/>
						<input
							type="text"
							placeholder="PSČ"
							value={zip}
							onChange={(e) => setZip(e.target.value)}
							style={{
								padding: '8px 16px',
								borderRadius: 100,
								border: '1px solid #aaaaaa',
								outlineColor: '#004d58',
								fontFamily: 'graphik_regular',
								width: '100%',
							}}
						/>
						<input
							type="text"
							placeholder="Adresa"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							style={{
								padding: '8px 16px',
								borderRadius: 100,
								border: '1px solid #aaaaaa',
								outlineColor: '#004d58',
								fontFamily: 'graphik_regular',
								width: '100%',
							}}
						/>
						<input
							type="text"
							placeholder="Číslo popisné"
							value={streetNumber}
							onChange={(e) => setStreetNumber(e.target.value)}
							style={{
								padding: '8px 16px',
								borderRadius: 100,
								border: '1px solid #aaaaaa',
								outlineColor: '#004d58',
								fontFamily: 'graphik_regular',
								width: '100%',
							}}
						/>
						<div>
							<Typography variant="body1" className="gutter-10">
								{/* <MobileTooltip title={t('inhabitants_tooltip')} element="inline"> */}
								<T translationKey="inhabitants" />
								{/* </MobileTooltip> */}
							</Typography>
							<NumberStepper value={inhabitants} setValue={setInhabitants} min={1} max={6} step={1} postfix="" />
						</div>
						<div>
							<Typography variant="body1" className="gutter-10">
								{/* <MobileTooltip title={t('bathrooms_tooltip')} element="inline"> */}
								<T translationKey="bathrooms" />
								{/* </MobileTooltip> */}
							</Typography>
							<NumberStepper value={bathrooms} setValue={setBathrooms} min={1} max={3} step={1} postfix="" />
						</div>
					</div>
					<div className="flex-end">
						<ActionButton onClick={createApartment} label={t('Vytvořit novou domácnost')} />
					</div>
				</div>
			</DialogCard>
		</>
	);
};

export default CreateApartmentDialog;
