import React from 'react';
import PropTypes from 'prop-types';
import '../styles/DropdownSelect.css';

const DropdownSelect = ({ label, value, setValue, placeholder, options, disabled }) => {
	const handleChange = (e) => {
		const value = e.target.value;
		const parsedValue = isNaN(Number(value)) ? value : Number(value);
		setValue(parsedValue);
	};

	return (
		<div className="dropdown-select-field">
			{label && <label className="dropdown-label">{label}</label>}
			<select className="dropdown-select" value={value ?? ''} onChange={handleChange} disabled={disabled}>
				<option value={-1} className="option-text" disabled>
					{placeholder}
				</option>
				{options.map((option) => (
					<option key={option.value} value={option.value} className="option-text">
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

DropdownSelect.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setValue: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		})
	).isRequired,
	disabled: PropTypes.bool,
};

DropdownSelect.defaultProps = {
	label: '',
	placeholder: '',
	value: '',
	setValue: () => {},
	options: [],
	disabled: false,
};

export default DropdownSelect;
