import { Alert, Button, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import LayoutCard from './Components/LayoutCard';
import ActionButton from './Components/ActionButton';
import { Link } from 'react-router-dom';

async function loginUserRequest(credentials) {
	return fetch(process.env.REACT_APP_API_URL + '/users/' + credentials.email, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'access-control-allow-origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Methods': '*',
		},
		body: JSON.stringify({
			email: credentials.email,
			password: credentials.password,
		}),
	})
		.then((response) => {
			if (response.status === 200) return response;

			if (response.status === 403) {
				throw new Error('Špatný email nebo heslo');
			}

			if (response.status === 500) throw new Error('Problém na serveru');

			throw new Error('Nastala nějaká chyba');
		})
		.then((data) => data.json())
		.catch((e) => {
			throw new Error(e.message);
		});
}

async function loginUserRequestOnlyEmail(credentials) {
	return fetch(process.env.REACT_APP_API_URL + '/users/passwordless/' + credentials.email, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'access-control-allow-origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Methods': '*',
		},
		body: JSON.stringify({
			email: credentials.email,
		}),
	})
		.then((response) => {
			if (response.status === 200) return response;

			if (response.status === 404) {
				throw new Error('Tento email neznáme');
			}

			if (response.status === 500) throw new Error('Problém na serveru');

			throw new Error('Nastala nějaká chyba');
		})
		.then((data) => data.json())
		.catch((e) => {
			throw new Error(e.message);
		});
}

const Login = () => {
	const [state, setState] = useState({
		alertClosed: false,
		error_msg: null,
	});
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [usingPassword, setUsingPassword] = useState(true);
	const navigate = useNavigate();
	const loginUser = () => {
		if (usingPassword) {
			loginUserRequest({ email: email, password: password })
				.then((data) => {
					localStorage.setItem('uid', data.user.user_id);
					localStorage.setItem('token', data.user.token);
					localStorage.setItem('first_time', 'no');
					localStorage.removeItem('error_msg');
					navigate('/profil');
				})
				.catch((e) => {
					localStorage.setItem('error_msg', e.message);
					setState((state) => {
						return { ...state, error_msg: e.message };
					});
					//console.log(e.message);
					return;
				});
		} else {
			loginUserRequestOnlyEmail({ email: email })
				.then((data) => {
					navigate('/emaily');
				})
				.catch((e) => {
					localStorage.setItem('error_msg', e.message);
					setState((state) => {
						return { ...state, error_msg: e.message };
					});
					//console.log(e.message);
					return;
				});
		}
	};

	const turnOffPassword = () => {
		setUsingPassword((state) => !state);
	};

	const closeAlert = () => {
		setState((state) => {
			return { ...state, alertClosed: true };
		});
		localStorage.removeItem('error_msg');
	};

	const mainPage = () => {
		navigate('/#form');
	};

	return (
		<ContentWrapper>
			{!state.alertClosed ? (
				<div>
					{localStorage.getItem('error_msg') != '' && localStorage.getItem('error_msg') != null ? (
						<Alert
							style={{
								position: 'fixed',
								inset: '5px 5px auto 5px',
								zIndex: 1100000,
							}}
							severity="error"
							variant="filled"
							onClose={closeAlert}
						>
							{localStorage.getItem('error_msg')}
						</Alert>
					) : null}
				</div>
			) : null}
			<div className="grid-2-mobile grid-center gap-40">
				<style>
					{`
					#card-1 {
						order: 1;
					}

					#card-2 {
						order: 2;
					}
					@media (max-width: 768px) {
						#card-1 {
							order: 2;
						}

						#card-2 {
							order: 1;
						}
					}
					`}
				</style>
				<div id="card-1">
					<Typography variant="h3" className="gutter-20">
						Výhody Youklid účtu
					</Typography>
					<Benefits />
				</div>
				<LayoutCard marginBottom={0} id="card-2">
					<div style={{ display: 'grid', height: '100%', gridGap: '20px', gridTemplateRows: 'auto auto 1fr' }}>
						<Typography variant="h3" className="gutter-20">
							Přihlášení
						</Typography>
						<div className="flex-between gap-10">
							<input
								type="text"
								value={email}
								placeholder="E-mail"
								onChange={(e) => setEmail(e.target.value)}
								style={{
									padding: '8px 16px',
									borderRadius: 100,
									border: '1px solid #aaaaaa',
									outlineColor: '#004d58',
									fontFamily: 'graphik_regular',
									width: '100%',
								}}
							/>
							{usingPassword && (
								<input
									type="password"
									value={password}
									placeholder="Heslo"
									onChange={(e) => setPassword(e.target.value)}
									style={{
										padding: '8px 16px',
										borderRadius: 100,
										border: '1px solid #aaaaaa',
										outlineColor: '#004d58',
										fontFamily: 'graphik_regular',
										width: '100%',
									}}
								/>
							)}
							{usingPassword ? (
								<div className="flex-end w-full">
									<ActionButton label={'Zapomenuté heslo'} onClick={turnOffPassword} color="muted" />
								</div>
							) : (
								<Alert severity="info" style={{ borderRadius: 16, width: '100%' }}>
									Na váš email vám zašleme odkaz pro přihlášení. Následně si můžete heslo změnit v nastavení.
								</Alert>
							)}
						</div>
						<div className="flex-between gap-10" style={{ alignSelf: 'end' }}>
							{usingPassword ? (
								<Link to={'/#form'}>
									<ActionButton label={'Založit účet'} icon={'left'} position="left" color="muted" />
								</Link>
							) : (
								<div />
							)}
							<ActionButton label={usingPassword ? 'Přihlásit se' : 'Odeslat přihlašovací email'} onClick={loginUser} color="primary" />
						</div>
					</div>
				</LayoutCard>
			</div>
		</ContentWrapper>
	);
};

const Benefits = () => {
	return (
		<>
			{[
				'Přehled a historie úklidů',
				'Změny a rušení objednávek',
				'Snadné objednání na pár kliků',
				'Kredit na vrácení přeplatků',
				'Hodnocení objednávek',
				'Sleva 200Kč pro vaše blízké',
				'Faktury z úklidů',
				'Rychlejší podpora a reklamace',
				'Nákup poukázek na úklid',
			].map((item, index) => (
				<Typography variant="body1" key={index} className="gutter-10">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						width="20"
						height="20"
						strokeWidth="1"
						style={{ display: 'inline-block', marginRight: 10 }}
					>
						<path d="M5 12l5 5l10 -10"></path>
					</svg>
					{item}
				</Typography>
			))}
			<Typography variant="h4" style={{ marginTop: 40, marginBottom: 20 }}>
				Chystáme do budoucna!
			</Typography>
			{['Výběr hospodyně', 'Odměny za věrnost', 'Akce na jarní a vánoční úklidy'].map((item, index) => (
				<Typography variant="body1" key={index} className="gutter-10">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						width="20"
						height="20"
						strokeWidth="1"
						style={{ display: 'inline-block', marginRight: 10 }}
					>
						<path d="M6.5 7h11"></path> <path d="M6.5 17h11"></path> <path d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"></path>
						<path d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"></path>
					</svg>
					{item}
				</Typography>
			))}
		</>
	);
};

export default Login;
