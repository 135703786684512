import { Typography, Button, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ContentWrapper from '../Wrapper/ContentWrapper';
import CountDown from '../CountDown';
import { useState } from 'react';

const NextOrderSent = (props) => {
	window.scrollTo(0, 0);
	let navigate = useNavigate();
	const [showPayButton, setShowPayButton] = useState(true);

	let payment = () => {
		navigate('/platba', true);
	};

	const hidePayButton = () => {
		setShowPayButton(false);
	};

  
	const [searchParams, setSearchParams] = useSearchParams();
	const URL_order_id = searchParams.get('order_id');
	const URL_date = searchParams.get('begin');
	const URL_repeating = searchParams.get('repeating') == 1;
	const URL_price = searchParams.get('price');
  localStorage.setItem('costs', URL_price);

	const dateDiff = getExactDayDifference(URL_date);

  function getExactDayDifference(dateString) {
		const givenDate = new Date(dateString);
		const today = new Date();

		// Normalize both dates to midnight (00:00:00) to remove time component
		givenDate.setHours(0, 0, 0, 0);
		today.setHours(0, 0, 0, 0);

		const diffTime = givenDate - today;
		return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  }

	return (
		<ContentWrapper>
			<Grid container justifyContent={'center'} style={{ marginTop: 0, marginBottom: 20 }}>
				<img src="img/checkmark.svg" width="101px" height="100px" alt="odesláno" />
			</Grid>
			<Typography variant="h1" style={{ textAlign: 'center' }}>
				Objednávka odeslána!
			</Typography>
			{URL_repeating ? (
				<Typography variant="body1" style={{ textAlign: 'center', color: '#327079', marginTop: 10 }}>
					První úklid je již za <strong>{dateDiff} dní</strong>
				</Typography>
			) : (
				<Typography variant="body1" style={{ textAlign: 'center', color: '#327079', marginTop: 10 }}>
					Úklid je již za <strong>{dateDiff} dní</strong>
				</Typography>
			)}

			{URL_price != null && (
				<>
					{URL_repeating ? (
						<Typography variant="body1" style={{ textAlign: 'center', color: '#327079' }}>
							Za první úklid zaplatíte <strong>{URL_price} Kč</strong>
						</Typography>
					) : (
						<Typography variant="body1" style={{ textAlign: 'center', color: '#327079' }}>
							Za úklid zaplatíte <strong>{URL_price} Kč</strong>
						</Typography>
					)}
				</>
			)}
			{/* <Alert severity="warning" style={{ width: "fit-content", margin: "auto", marginTop: 40 }}>Objednávka bude přijata až po její úhradě.</Alert> */}
			<Typography variant="body1" style={{ marginTop: 30, textAlign: 'center', color: '#327079' }}>
				Platbu objednávky prosím proveďte do <strong>30{'\u00A0'}minut</strong>,
				<br /> v opačném případě bude zrušena a rezervovaný
				<br /> čas uvolněn pro jiného zákazníka.
			</Typography>
			<Grid container justifyContent={'center'}>
				<CountDown hidePayButton={hidePayButton} order_id={URL_order_id} />
			</Grid>
			{showPayButton && (
				<Grid container justifyContent={'center'}>
					<Button
						onClick={payment}
						style={{
							borderRadius: 100,
							width: 260,
							background: '#8c5cbc',
							color: '#fbfdfc',
							fontSize: 20,
							fontFamily: 'agrandir',
							textTransform: 'initial',
							marginTop: 60,
						}}
					>
						<span style={{ position: 'relative', top: 2 }}>Zaplatit objednávku</span>
					</Button>
				</Grid>
			)}
		</ContentWrapper>
	);
};
export default NextOrderSent;
