import React, { useEffect } from 'react';
import ActionButton from './ActionButton';
import usePersistentState from '../hooks/usePersistentState';
import useApiKey from '../hooks/useApiKey';
import { Alert } from '@mui/material';

const VerifyCoupon = ({ coupon, setCoupon }) => {
	const { apiKey } = useApiKey();
	const [couponField, setCouponField] = usePersistentState('coupon_field', '');
	const [success, setSuccess] = usePersistentState('coupon_success', false);
	const [errors, setErrors] = usePersistentState('coupon_errors', []);

	useEffect(() => {
		const url = `${process.env.REACT_APP_API_URL}/v1/coupon?coupon=${encodeURIComponent(coupon)}`;
		fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${apiKey}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					return response.json().then((errorData) => {
						if (errorData.errors && Array.isArray(errorData.errors)) {
							setSuccess(false);
							setErrors(errorData.errors);
							setCouponField('');
						}
						throw new Error('API Error');
					});
				}
				return response.json();
			})
			.then((data) => {
				setSuccess(true);
				setCouponField(data.coupon.coupon_code);
				setErrors([]);
			})
			.catch((error) => {
				console.error('Error:', error.message);
			});
	}, [coupon]);

	return (
		<>
			{coupon != null &&
				coupon != '' &&
				errors.map((error) => (
					<Alert style={{ borderRadius: 16 }} severity="warning" className="gutter-20">
						{error}
					</Alert>
				))}
			{success && (
				<Alert style={{ borderRadius: 16 }} severity="success" className="gutter-20">
					Váš kód byl uplatněn.
				</Alert>
			)}
			<div className="grid-2-col-asymmetric gap-10-20">
				<input
					type="text"
					value={couponField}
					onChange={(e) => setCouponField(e.target.value)}
					style={{
						padding: '8px 16px',
						borderRadius: 100,
						border: '1px solid #aaaaaa',
						outlineColor: '#004d58',
						fontFamily: 'graphik_regular',
						width: '100%',
					}}
				/>
				<ActionButton
					variant="contained"
					color="secondary"
					onClick={() => {
						setCoupon(couponField.toUpperCase());
					}}
					label={'Uplatnit'}
				/>
			</div>
		</>
	);
};

export default VerifyCoupon;
