import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import useIsMobile, { Default, Mobile } from './lib/Responsive';
import { Button, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuDropdown from './MenuDropdown';
import MobileMenuAccordion from './MobileMenuAccordion';

const MenuTest = (props) => {
	let navigate = useNavigate();
	const isMobile = useIsMobile();
	const [menuOpen, setMenuOpen] = useState(false);
	const link = (link, e) => {
		e.preventDefault();
		navigate(link);
	};
	const CTA_copy = 'Kalkulace'; // = "Objednat";
	const showFAQ = props.showFAQ ?? true;
	// const bannerText = (
	// 	<>
	// 		Každý třetí vyhrává! Darujte svým blízkým{' '}
	// 		<a href="/poukazky" style={{ textDecoration: 'underline' }}>
	// 			Youklid poukázku
	// 		</a>{' '}
	// 		a získejte druhou zdarma.
	// 	</>
	// );
	const bannerText = null;

	return (
		<>
			{isMobile && menuOpen ? <MobileMenuOpen setMenuOpen={setMenuOpen} CTA_copy={CTA_copy} useAnchors={window.location.pathname === '/'} /> : null}
			{bannerText != null && <div style={{ height: isMobile ? 72 : 48 }} />}
			<header
				style={{
					position: 'fixed',
					zIndex: 10,
					top: 0,
					left: 0,
					right: 0,
					backgroundColor: '#fff',
					transition: 'top 0.3s',
				}}
			>
				{!menuOpen && bannerText != null && (
					<Typography style={{ background: '#8c5cbc', color: '#ffffff', fontFamily: 'agrandir', padding: '12px 24px', textAlign: 'center' }}>
						{bannerText}
					</Typography>
				)}
				<Grid container justifyContent={'space-between'} style={{ padding: isMobile ? '14px 16px' : '12px' }}>
					<Grid item>
						<Link to="/">
							<img src="/img/logo_menu.svg" height={46} width={100} style={{ margin: isMobile ? '0' : '12px 24px' }} alt="logo youklid" />
						</Link>
					</Grid>
					<Default>
						<Grid item>
							<div
								style={{
									height: '100%',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								{/* <a
									href="tel:+420216216056"
									style={{
										display: "block",
										backgroundColor: "transparent",
										borderColor: "transparent",
										borderRadius: "400px",
										marginRight: "10px",
										//padding: 30,
										fontFamily: "agrandir",
										fontSize: 16,
										textTransform: "initial",
										boxShadow: "none",
										color: "#004D58",
									}}
									variant="contained"
								>
									<svg xmlns="http://www.w3.org/2000/svg" className="inline icon icon-tabler icon-tabler-phone" width="24" height="24" viewBox="0 0 24 24" strokeWidth="0" stroke="#004d58" fill="#004d58" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none" />
										<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
									</svg>
									<style>
										{
											`.phone_underline:hover{
												text-decoration: underline;
											}`
										}
									</style>
									<span style={{ marginLeft: 8 }} className='phone_underline'>216 216 056777</span>
								</a> */}
								<style>
									{`
										#faq-button:hover{
											background: #f6f6f6!important;
										}
									`}
								</style>
								{showFAQ && (
									<Link
										to="/casto-kladene-otazky"
										style={{
											fontFamily: 'agrandir',
											fontSize: 16,
											textTransform: 'initial',
											boxShadow: 'none',
											color: '#004D58',
											border: '1px solid #004D58',
											padding: ' 8px 24px',
											borderRadius: 100,
											background: 'transparent',
											transition: '0.1s ease',
										}}
										id="faq-button"
										className="squash"
										target="_blank"
										rel="noopener noreferrer"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="inline icon icon-tabler icon-tabler-phone"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											strokeWidth="0"
											stroke="#004d58"
											fill="#004d58"
											strokeLinecap="round"
											strokeLinejoin="round"
										>
											<path stroke="none" d="M0 0h24v24H0z" fill="none" />
											<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
										</svg>
										<span style={{ position: 'relative', top: 2 }}>Potřebujete poradit?</span>
									</Link>
								)}
							</div>
						</Grid>
						<Grid
							item
							style={{
								height: 71,
								display: 'flex',
							}}
						>
							<nav
								className="squash-margin"
								style={{
									height: '100%',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									marginRight: 24,
								}}
							>
								<style>
									{`.gray_button:hover{
											background: #fafafa!important;
										}
										@media (max-width: 1250px) {
											.squash-margin {
												margin-right: 8px!important;
											}
											.squash-margin-left {
												margin-left: 8px!important;
											}
											.squash{
												display: none!important;
											}
										}
										`}
								</style>
								{[
									{
										title: 'Poukázky',
										url: '/poukazky',
										squash: true,
									},
									{
										title: 'Zaměstnání',
										url: '/prace',
									},
									{
										title: 'Ceník',
										url: '/cenik',
									},
								].map((link, index) => (
									<Link
										key={index}
										to={link.url}
										className={`gray_button squash-margin ${link.squash ? 'squash' : ''}`}
										style={{
											background: 'transparent',
											position: 'relative',
											top: -2,
											padding: '6px 12px',
											marginRight: 28,
											fontFamily: 'agrandir',
											fontSize: 16,
											color: '#004D58',
											borderRadius: 400,
											border: '1px solid transparent',
											transition: '0.1s ease',
										}}
									>
										<span style={{ position: 'relative', top: 2 }}>{link.title}</span>
									</Link>
								))}
								{/* <AnchorLink href="#priklady-naceneni">
									<Button
										onClick={(e) => link("/", e)}
										style={{
											display: "block",
											backgroundColor: "transparent",
											border: "1px solid #004d58",
											borderRadius: "400px",
											padding: "8px 24px",
											//height: 55,
											fontFamily: "agrandir",
											fontSize: 16,
											textTransform: "initial",
											boxShadow: "none",
											color: "#004d58",
											marginLeft: "24px",
										}}
										variant="contained"
									>
										<span style={{ position: "relative", top: "2px" }}>Příklady nacenění</span>
									</Button>
								</AnchorLink> */}
								<MenuDropdown
									title={'Informace'}
									className="gray_button squash-margin"
									links={[
										{ title: 'O nás', url: '/o-nas' },
										{ title: 'Etika', url: '/eticky-kodex' },
										{ title: 'Poukázky', url: '/poukazky' },
										{ title: 'Média', url: '/media' },
										{ title: 'Časté dotazy', url: '/casto-kladene-otazky' },
										{ title: 'Kontakty', url: '/kontakt' },

										// { title: 'Často kladené otázky', url: '/faq', },
										// { title: 'Ceník', url: '/cenik', },
										// { title: 'Pomoc', url: '/pomoc', },
										// { title: 'Všeobecné obchodní podmínky', url: '/vseobecne-obchodni-podminky', },
										// { title: 'Ochrana osobních údajů', url: '/ochrana-osobnich-udaju', },
									]}
								/>
								{/* <MenuDropdown title={"Služby"} links={[
									{ title: 'Váš první Youklid', url: '/prvni-uklid', },
									{ title: 'Standardní úklid', url: '/sluzby', },
									{ title: 'Doplňkové služby', url: '/doplnkove-sluzby', },
									{ title: 'Kde uklízíme', url: '/dostupne-lokality', },
									{ title: 'Kapacity', url: '/dostupne-kapacity', },
									
									// { title: 'Produkty', url: '/produkty', },
									// { title: 'Ceník', url: '/cenik', },
									// { title: 'Pomoc', url: '/pomoc', },
								]} /> */}
								{!props.hideButtons && (
									<>
										{props.useAnchors ? (
											<AnchorLink
												href="#form"
												onClick={() => {
													props.setMenuOpen((menuOpen) => false);
												}}
											>
												<style>
													{`#cta_button_1:hover{
													background: #7d51a8!important;
												}`}
												</style>
												<Button
													id="cta_button_1"
													style={{
														backgroundColor: '#8C5CBC',
														borderColor: '#8C5CBC',
														borderRadius: '400px',
														fontFamily: 'agrandir',
														textTransform: 'initial',
														fontSize: 16,
														padding: '8px 24px',
														boxShadow: 'none',
														transition: '0.1s ease',
														':hover': {
															backgroundColor: '#7d51a8',
														},
													}}
													variant="contained"
												>
													<span style={{ position: 'relative', top: '2px' }}>{CTA_copy}</span>
												</Button>
											</AnchorLink>
										) : (
											<Link to="/#form">
												<style>
													{`#cta_button_2:hover{
													background: #7d51a8!important;
												}`}
												</style>
												<Button
													id="cta_button_2"
													style={{
														backgroundColor: '#8C5CBC',
														borderColor: '#8C5CBC',
														borderRadius: '400px',
														fontFamily: 'agrandir',
														textTransform: 'initial',
														fontSize: 16,
														padding: '8px 24px',
														boxShadow: 'none',
														transition: '0.1s ease',
														':hover': {
															backgroundColor: '#7d51a8',
														},
													}}
													variant="contained"
												>
													<span style={{ position: 'relative', top: '2px' }}>{CTA_copy}</span>
												</Button>
											</Link>
										)}
									</>
								)}
								<Button
									onClick={(e) => link('/profil', e)}
									className="gray_button squash-margin-left"
									style={{
										display: 'block',
										backgroundColor: 'transparent',
										//border: "1px solid #004d58",
										borderRadius: '400px',
										fontFamily: 'agrandir',
										fontSize: 16,
										textTransform: 'initial',
										boxShadow: 'none',
										color: '#004d58',
										marginLeft: 24,
									}}
									variant="contained"
								>
									<img src="/img/profile.svg" alt="profil" style={{ display: 'inline', marginRight: 9, width: 22 }} />
									<span style={{ position: 'relative', top: '2px' }}>Profil</span>
								</Button>
							</nav>
						</Grid>
					</Default>
					<Mobile>
						<Grid
							item
							onClick={() => {
								setMenuOpen((menuOpen) => !menuOpen);
							}}
							style={{ display: 'block' }}
						>
							{menuOpen ? <img src="/img/cross.svg" alt="close" /> : <img src="/img/burgir.svg" alt="menu" width={54} height={44} />}
						</Grid>
					</Mobile>
				</Grid>
			</header>
		</>
	);
};

const MobileMenuOpen = (props) => {
	const isMobile = useIsMobile();
	let navigate = useNavigate();
	const link = (link, e) => {
		e.preventDefault();
		navigate(link);
	};
	return (
		<div
			style={{
				position: 'fixed',
				inset: isMobile ? '75px 0 0 0' : '95px 0 0 0',
				background: '#fff',
				zIndex: 999999,
			}}
		>
			<Grid
				container
				direction={'column'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				style={{ height: '100%', paddingBottom: 50, flexWrap: 'nowrap' }}
			>
				{[
					{
						title: 'Profil',
						fragment: '/profil',
						element: (
							<div
								onClick={(e) => {
									link('/profil', e);
								}}
							>
								<img src="/img/profile.svg" alt="profil" style={{ display: 'inline', marginRight: 9, width: 20 }} />
								<span style={{ position: 'relative', top: '4px' }}>Profil</span>
							</div>
						),
					},
					{
						title: 'Ceník',
						fragment: '/cenik',
						element: <Link to="/cenik">Ceník</Link>,
					},
					{
						title: 'Zaměstnání',
						fragment: '/prace',
						element: <Link to="/prace">Zaměstnání</Link>,
					},
					// {
					// 	title: 'Naši profesionálové',
					// 	fragment: '#team',
					// 	element: null,
					// },
					// {
					// 	title: 'Jak to funguje',
					// 	fragment: '#process',
					// 	element: null,
					// },
					// {
					// 	title: 'Příklady nacenění',
					// 	fragment: '#priklady-naceneni',
					// 	element: null,
					// },
					// {
					// 	title: 'O nás',
					// 	fragment: '#o-youklidu',
					// 	element: null,
					// },
					{
						title: '',
						fragment: '',
						element: <MobileMenuAccordion style={{ width: '100%' }} />,
					},
					{
						title: 'O nás',
						fragment: '#form',
						element: (
							<>
								{props.useAnchors ? (
									<AnchorLink
										href="#form"
										onClick={() => {
											props.setMenuOpen((menuOpen) => false);
										}}
									>
										<Button
											style={{
												backgroundColor: '#8C5CBC',
												borderColor: '#8C5CBC',
												borderRadius: '400px',
												fontFamily: 'agrandir',
												textTransform: 'initial',
												fontSize: 20,
												padding: '8px 36px',
												boxShadow: 'none',
											}}
											variant="contained"
										>
											<span style={{ position: 'relative', top: '2px' }}>{props.CTA_copy}</span>
										</Button>
									</AnchorLink>
								) : (
									<Link to="/#form">
										<Button
											style={{
												backgroundColor: '#8C5CBC',
												borderColor: '#8C5CBC',
												borderRadius: '400px',
												fontFamily: 'agrandir',
												textTransform: 'initial',
												fontSize: 20,
												padding: '8px 36px',
												boxShadow: 'none',
											}}
											variant="contained"
										>
											<span style={{ position: 'relative', top: '2px' }}>{props.CTA_copy}</span>
										</Button>
									</Link>
								)}
							</>
						),
					},
				].map((anchor, index) => (
					<Grid
						item
						key={index}
						style={{
							fontSize: 20,
							fontFamily: 'agrandir',
							color: '#004D58',
							marginBottom: 24,
						}}
					>
						{anchor.element != null ? (
							anchor.element
						) : (
							<AnchorLink
								href={anchor.fragment}
								onClick={() => {
									props.setMenuOpen(false);
								}}
							>
								{anchor.title}
							</AnchorLink>
						)}
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default MenuTest;
