import { Box, Fade, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; import { Link } from 'react-router-dom';

const MenuDropdown = (props) => {

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<React.Fragment>
			<style>
				{`
				@media (max-width: 1250px) {
					.squash-margin {
						margin-right: 10px!important;
					}
				}
				`}
			</style>
			<Box
				style={{
					display: 'flex',
					alignItems: 'center',
					textAlign: 'center',
					marginRight: 40,
					borderRadius: 400,
					position: 'relative',
					top: -2,
					padding: '2px 4px 2px 10px',
				}}
				className={`${props.className} squash-margin`}
			>
				<IconButton
					onClick={handleClick}
					size="small"
					disableRipple
					aria-controls={open ? 'menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				>
					<span
						style={{
							fontFamily: 'agrandir',
							fontSize: 16,
							color: '#004D58',
							position: 'relative',
							top: 2,
						}}
					>
						{props.title} <KeyboardArrowDownIcon style={{ position: 'relative', top: -2 }} />
					</span>
				</IconButton>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				disableScrollLock={true}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						borderRadius: 0,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 12,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				TransitionComponent={Fade}
			>
				{props.links.map((link, index) => (
					<Link key={index} to={link.url} onClick={handleClose}>
						<MenuItem
							style={{
								fontFamily: 'agrandir',
								fontSize: 16,
								color: '#004D58',
							}}
						>
							<div style={{ marginRight: 40 }}>{link.title}</div>
						</MenuItem>
					</Link>
				))}
			</Menu>
		</React.Fragment>
	);
};

export default MenuDropdown;