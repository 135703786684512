import { useState, useEffect } from 'react';

function useLang(lang = 'cs') {
	const [translations, setTranslations] = useState({});

	useEffect(() => {
		const fetchedTranslations = fetchTranslations(lang);
		setTranslations(fetchedTranslations);
	}, [lang]);

	const t = (translationKey, values = {}) => {
		const template = translations[translationKey] || translationKey; // Fallback to key
		return interpolate(template, values);
	};

	const T = ({ translationKey, values = {} }) => {
		return <>{t(translationKey, values)}</>;
	};

	return { T, t };
}

// Helper to fetch translations (mocked)
function fetchTranslations(lang) {
	const translations = {
		cs: {
			delete_progress: 'Přeji si smazat dosud vyplněné údaje a začít znova.',
			form_headline: 'Objednávka',
			form_intro:
				'Nově jsme vám ulehčili objednání pokud jste už u nás úklid objednávali. Tento kratší formulář obsahuje spousty novinek o který se dozvíte v modrých boxech v průběhu.',
			persistent_info:
				'Nejvíce jste si stěžovali, že se obsah formuláře vymaže při obnovení stránky. Nyní obsah zůstává a jde smazat pouze pomocí tlačítka "Začít znovu".',
			persistent_clear: 'Začít znovu',
			persistent_are_you_sure:
				'Po smazání se odstraní všechna data, která jste vyplnili do formuláře. Zůstanou pouze informace spojené s vaším profilem, jako je seznam domácností a fakturační údaje – ty si můžete kdykoli upravit ve svém profilu. Jste si jistí, že chcete začít znovu?',
			persistent_confirm: 'Ano, vymazat',
			persistent_continue: 'Ne, chci pokračovat',
			open_calculator: 'Kalkulačka',
			close_calculator: 'Zavřít',
			calculator: 'Kalkulačka',
			praha: 'Praha',
			city_label: 'Město / Obec',
			city_placeholder: 'Vyberte město kde se nacházíte...',
			select_option: 'Vyberte možnost...',
			method: 'Chci k objednávce přidat úklidový balíček method',
			welcome_message: 'Vítejte na našem webu!',
			greeting: 'Ahoj, {name}!',
			cucumber: {
				1: 'okurka',
				'2_4': 'okurky',
				'5+': 'okurek',
			},
			hours: {
				1: 'hodina',
				'2_4': 'hodiny',
				'5+': 'hodin',
			},
			apartment_label: 'Kde budeme uklízet?',
			apartment: 'Domácnost',
			apartment_placeholder: 'Vyberte z existujících domácností',
			new_apartment: 'Nová domácnost',
			close: 'Zavřít',
			inhabitants: 'Počet osob včetně dětí',
			bathrooms: 'Počet koupelen mimo toalety',
			inhabitants_tooltip:
				'Celkový počet osob žijících ve vaší domácnosti nám lépe dovolí porozumět o jaký typ domácnosti se jedná. Jinou službu vám poskytneme pokud žijete sami, než když jste čtyřčlenná rodina. Pokud se jedná o čtyřnohé společníky, můžete nám přesné instrukce napsat do poznámky. Stejně tak, pokud vás společně bydlí více než šest, což je limit naší kalkulačky.',
			bathrooms_tooltip:
				'Koupelna při úklidu zabere více času než jiné místnosti. Proto potřebujeme znát jejich počet, aby každá dostala péči, kteoru si zaslouží. V případě, že váš domov překračuje maximum tří koupelen, kterým je limitovaná naše kalkulačka, napište nám do poznámky více.',
			services: 'Úklid a další služby',
			hours: 'Hodiny na úklid',
			hours_tooltip: 'Youklid si objednáváte na pevný počet hodin. Případný přeplatek vám bude vrácen do kreditu.',
			profile_are_you_sure:
				'Chcete opravdu přejít na profil? Veškeré údaje, které jste zde vyplnili tady na vás budou čekat, až se vrátíte. Pokud chcete smazat vyplněný obsah, použijte ikonku koše vpravo nahoře.',
			profile_continue: 'Pokračovat v objednávce',
			profile_confirm: 'Přejít do profilu',
		},
		en: {
			open_calculator: 'Kalkulačka',
			praha: 'Praha',
			city_label: 'Město / Obec',
			city_placeholder: 'Vyberte město kde se nacházíte...',
			select_option: 'Select an option...',
			method: 'I would like a method package',
			welcome_message: 'Welcome to our site!',
			greeting: 'Hello, {name}!',
			cucumber: {
				1: 'cucumber',
				'2_4': 'cucumbers',
				'5+': 'cucumbers',
			},
			hours: {
				1: 'hour',
				'2_4': 'hours',
				'5+': 'hours',
			},
		},
	};

	return translations[lang] || translations['cs']; // Default to Czech
}

// Helper to interpolate placeholders
function interpolate(template, values) {
	if (typeof template === 'object') {
		// Handle pluralization
		const number = values.number;
		if (number === 1) return template['1'];
		if (number >= 2 && number <= 4) return template['2_4'];
		return template['5+'];
	}

	return template.replace(/\{(\w+)\}/g, (_, key) => values[key] || `{${key}}`);
}

export default useLang;

{
	/* 
	examples
	<p>
					<T translationKey="greeting" values={{ name: 'Lukáš' }} />
				</p>
				<p>
					<T translationKey="non_existent_key" />
				</p>
				<p>
					1 <T translationKey="cucumber" values={{ number: 1 }} /> // okurka
				</p>
				<p>
					3 <T translationKey="cucumber" values={{ number: 3 }} /> // okurky 
				</p>
				<p>
					5 <T translationKey="cucumber" values={{ number: 5 }} /> // okurek 
				</p>
				<p>
					1 <T translationKey="hours" values={{ number: 1 }} /> // hodina 
				</p>
				<p>
					4 <T translationKey="hours" values={{ number: 4 }} /> // hodiny 
				</p>
				<p>
					100 <T translationKey="hours" values={{ number: 100 }} /> // hodin 
				</p>

				 */
}
