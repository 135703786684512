import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import '../styles/NumberStepper.css';

const NumberStepper = ({ value, setValue, min, max, step, postfix }) => {
	const increment = () => {
		setValue((prev) => Math.min(max, prev + step));
	};

	const decrement = () => {
		setValue((prev) => Math.max(min, prev - step));
	};

	return (
		<div className="container">
			<button
				onClick={decrement}
				className="stepper button"
				style={{
					borderTopLeftRadius: 100,
					borderBottomLeftRadius: 100,
				}}
			>
				-
			</button>
			<input type="text" className="field" value={value + postfix} readOnly />
			<button
				onClick={increment}
				className="stepper button"
				style={{
					borderTopRightRadius: 100,
					borderBottomRightRadius: 100,
				}}
			>
				+
			</button>
		</div>
	);
};

NumberStepper.propTypes = {
	value: PropTypes.number,
	setValue: PropTypes.func,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	postfix: PropTypes.string,
};

NumberStepper.defaultProps = {
	value: 0,
	setValue: () => {},
	min: 0,
	max: 10,
	step: 1,
	postfix: '',
};

export default NumberStepper;
