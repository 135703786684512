import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersistentState from './usePersistentState';

export const useApiKey = () => {
	const navigate = useNavigate();
	const [apiKey, setApiKey] = usePersistentState('api_key', '', 'user_data_');
	const [loading, setLoading] = useState(true);
	const apiKeyPromiseRef = useRef(null); // Track the request

	useEffect(() => {
		const fetchKey = async () => {
			if (apiKey) {
				setLoading(false);
				return;
			}

			const uid = localStorage.getItem('uid');
			const token = localStorage.getItem('token');

			if (!uid || !token) {
				localStorage.removeItem('uid');
				localStorage.removeItem('token');
				setApiKey('');
				setLoading(false);
				return;
			}

			if (!apiKeyPromiseRef.current) {
				const url = `${process.env.REACT_APP_API_URL}/api-token/${uid}/create?token=${token}`;
				apiKeyPromiseRef.current = fetchApiKey(url)
					.then((data) => {
						if (data.apiKey) {
							setApiKey(data.apiKey);
						} else {
							console.error('Failed to generate API key');
							setApiKey('');
						}
					})
					.catch((error) => {
						console.error('Error fetching API key:', error);
						setApiKey('');
					})
					.finally(() => {
						apiKeyPromiseRef.current = null;
						setLoading(false);
					});
			} else {
				apiKeyPromiseRef.current.finally(() => setLoading(false));
			}
		};

		if (!apiKey) {
			fetchKey();
		} else {
			setLoading(false);
		}
	}, [apiKey]);

	return { apiKey, loading };
};

async function fetchApiKey(url) {
	const response = await fetch(url, { method: 'POST' });
	if (!response.ok) {
		throw new Error('Failed to fetch API key');
	}
	return response.json();
}

export default useApiKey;
